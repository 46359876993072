<template>
  <div>
    <van-tabbar fixed v-model="active" active-color="#00A362" inactive-color="#888" @change="tabChange">
      <van-tabbar-item :name="item.text" replace v-for="item in mapBottomNav" :key="item.id">
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.activeUrl : item.imgUrl" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "MapBottomNav",
  data() {
    return {
      mapBottomNav: [
        {
          id: 0,
          imgUrl: require("../assets/images/w1s.png"),
          activeUrl: require("../assets/images/z1s.png"),
          text: "学校",
        },
        {
          id: 1,
          imgUrl: require("../assets/images/w2s.png"),
          activeUrl: require("../assets/images/z2s.png"),
          text: "医院",
        },
        {
          id: 2,
          imgUrl: require("../assets/images/w3s.png"),
          activeUrl: require("../assets/images/z3s.png"),
          text: "购物",
        },
        {
          id: 3,
          imgUrl: require("../assets/images/w4s.png"),
          activeUrl: require("../assets/images/z4s.png"),
          text: "银行",
        },
        {
          id: 4,
          imgUrl: require("../assets/images/w5s.png"),
          activeUrl: require("../assets/images/z5s.png"),
          text: "公交",
        },
        {
          id: 5,
          imgUrl: require("../assets/images/subway.png"),
          activeUrl: require("../assets/images/subway_g.png"),
          text: "地铁",
        },
      ],
      active:"学校"
    };
  },
  methods: {
    tabChange(active){
      this.$emit("tabChange",active)
    }
  },
  props:['tabActive'],
  mounted(){
    this.active = this.tabActive;
  }
    
  
};
</script>
<style lang="less" scoped>
</style>