<template>
  <div class="mapDetail">
    <navbar title="位置及周边" />
    <div>
      <baidu-map class="bm-view" ak="3m0AqdZmu1466T4OmwHbwhGSCBFEAGrM" :dragging="dragging" :center="center" :zoom="zoom" :min-zoom="1" :max-zoom="19" @touchmove="touchmove" @touchend="touchend" @ready="handler">
        <bm-overlay pane="labelPane" style="position: relative" @draw="draw">
          <div class="sample" @click="handle">
            <img src="../../assets/images/dws.svg" alt="" />
          </div>
        </bm-overlay>
        <div v-for="item in list" :key="item.aroundtitle">
          <icon-overlay @handleClick="handle" :msg="item" :position="{ lng: item.lng, lat: item.lat }" :active="active" />
        </div>

        <bm-info-window :position="position" title="周边信息" :show="show" :width="220" :height="0" @close="closeMsg">
          <div class="info-title">{{msg.name}}</div>
          <p>地址：{{msg.address}}</p>
          <p>电话：{{msg.mobile}}</p>
        </bm-info-window>
      </baidu-map>
    </div>
    <!-- 底部 -->
    <map-bottom-nav @tabChange="tabChange" :tabActive="tabActive"></map-bottom-nav>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import mapBottomNav from "../../components/MapBottomNav.vue";
import IconOverlay from "../../components/map/MapIcon.vue";
import { BaiduMap, BmOverlay, BmInfoWindow } from "vue-baidu-map";
export default {
  name: "MapDetail",
  data() {
    return {
      center: { lng: 118.097244, lat: 24.520152 },
      zoom: 13,
      list: [],
      list03: [],
      list04: [],
      list05: [],
      list06: [],
      dragging: false,
      active: "",
      msg: "",
      position: {},
      show: false,
      tabActive: "",
      type:""
    };
  },
  created() {
    this.tabActive = this.$route.query.active;
    this.active = this.tabActive
    this.center.lat=this.$route.query.lat;
    this.center.lng=this.$route.query.lng;
    this.id = this.$route.query.id
    console.log("zzz",this.tabActive,"zzz",this.id)
    let type = "";
    if (this.tabActive=="学校"){
      this.type=1
    }
    if (this.tabActive=="医院"){
      this.type=2
    }
    if (this.tabActive=="购物"){
      this.type=3
    }
    if (this.tabActive=="银行"){
      this.type=4
    }
    if (this.tabActive=="公交"){
      this.type=5
    }
    if (this.tabActive=="地铁"){
      this.type=6
    }
    this.getHouseAround(this.id,this.type);
  },
  mounted() {
    // 进入页面渲染的数据
    this.getHouseAround(this.id,this.type);
    // this.list = this.list01;
  },
  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.center;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 16 + "px";
      el.style.top = pixel.y - 16 + "px";
    },
    touchmove() {
      this.dragging = true;
    },
    touchend() {
      this.dragging = false;
    },
    handler({ BMap, map }) {
      this.BMap = BMap;
      this.map = map;
    },
    // 底部导航切换触发事件
    tabChange(active) {
      this.active = active;
      this.show = false;
      this.map.setCenter(new BMap.Point(this.center.lng, this.center.lat));
      this.map.setZoom(13);

      if (active == "学校") {
        this.getHouseAround(this.id,1)
      } else if (active == "医院") {
        this.getHouseAround(this.id,2)
      } else if (active == "购物") {
        this.getHouseAround(this.id,3)
      }else if (active == "银行") {
        this.getHouseAround(this.id,4)
      }else if (active == "公交") {
        this.getHouseAround(this.id,5)
      }else if (active == "地铁") {
        this.getHouseAround(this.id,6)
      }

    },

    getHouseAround(id,type){
      console.log("获取的id"+id+"获取的类型"+type)
      const data={
        id:id,
        type:type
      }
      this.$post("home/getHouseAround",data).then((res)=>{
        if (res.status==200){
          console.log("zzz",res.data.count)
          console.log("res",res.data.list)

          if (res.data.list == [] || res.data.list.length == 0 || res.data.count==0) {
            this.list=[];
            this.$toast("暂时没有相关信息！");
          }else {
            this.list = res.data.list
          }
        }
      })
    },

    handle(msg, position) {
      this.msg = msg;
      this.position = position;
      this.show = true;
    },
    closeMsg() {
      this.show = false;
    },
  },
  components: {
    navbar,
    mapBottomNav,
    BaiduMap,
    BmOverlay,
    IconOverlay,
    BmInfoWindow,
  },
};
</script>
<style lang="less" scoped>
.mapDetail {
  .bm-view {
    width: 100%;
    height: calc(100vh - 46px);
  }
  /deep/.info-title {
    color: #00a362;
    font-size: 12px;
  }
  /deep/.BMap_bubble_content,
  /deep/.BMap_bubble_title {
    color: #000;
    font-size: 12px;
  }
  .bm-info-window {
    p {
      font-size: 12px;
    }
  }
  .sample {
    width: 32px;
    height: 32px;
    position: absolute;
  }
}
</style>
