<template>

  <bm-overlay ref="customOverlay" pane="labelPane" @draw="draw" style="position: relative">
    <div class="icon-sample" @click="handleClick">
      <img src="../../assets/images/yu.svg" alt="" v-if="active=='学校'" />
      <img src="../../assets/images/yi.svg" alt="" v-if="active=='医院'" />
      <img src="../../assets/images/gw.svg" alt="" v-if="active=='购物'" />
      <img src="../../assets/images/yh.svg" alt="" v-if="active=='银行'" />
      <img src="../../assets/images/gj.svg" alt="" v-if="active=='公交'" />
      <img src="../../assets/images/cg.svg" alt="" v-if="active=='地铁'" />
    </div>
  </bm-overlay>
</template>
<script>
import { BmOverlay } from "vue-baidu-map";
export default {
  name: "mapIcon",
  data() {
    return {};
  },
  methods: {
    draw({ el, BMap, map }) {
      const { lng, lat } = this.position;
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - 16 + "px";
      el.style.top = pixel.y - 16 + "px";
    },
    handleClick() {
      this.$emit("handleClick", this.msg, this.position);
    },
  },
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload();
      },
      deep: true,
    },
  },
  props: ["msg", "position", "active"],
  components: {
    BmOverlay,
  },
};
</script>
<style lang="less" scoped>
.icon-sample {
  width: 32px;
  height: 32px;
  position: absolute;
}
</style>